@import url('https://fonts.googleapis.com/css2?family=Jost:wght@400;500;600&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap");

body {
	font-family: "Open Sans", sans-serif;
	margin: 0;
	min-height: 100vh;
	background-color: #f9f9f9;
}

.App {
	text-align: center;
	padding: 20px;
	background-color: #f9f9f9;
}

.app-container {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	gap: 20px;
}

.Runs,
.Agents,
.LLMCalls {
	border-radius: 10px;
	padding: 20px;
	background: #f5f5f5;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
	height: 90vh;
	display: flex;
	flex-direction: column;
}

.Runs {
	width: 20%;
}

.Agents {
	width: 12%;
	min-width: 200px;
}

.LLMCalls {
	width: 87%;
}

.Runs button,
.Agents button,
.LLMCalls button {
	display: block;
	text-align: left;
	width: 100%;
	padding: 10px;
	margin-bottom: 10px;
	border: none;
	border-radius: 5px;
	transition: background-color 0.2s ease;
}

button.selected {
	color: black;
	transition: box-shadow 0.3s ease;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
}

button:hover,
select:hover {
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6);
	cursor: pointer;
}

button.selected:hover {
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
}

h2 {
	text-align: center;
	margin-bottom: 20px;
}

.attempt-failed,
.run-failed,
.attempt-incomplete {
	/* FIXME database to make false and failed consistent */
	background-color: #f1b8b3;
	color: black;
}

.run-in-progress {
	background-color: #fff9b0;
	color: black;
}

.attempt-completed,
.run-completed {
	background-color: #bce0b7;
	color: black;
}

.run-warning {
	background-color: #fada67;
	color: black;
}


.search-input {
	padding: 0.5rem;
	border: 1px solid #ccc;
	border-radius: 5px; /* Match border-radius */
	font-size: 1rem; /* Match font-size */
	font-family: "Open Sans", sans-serif; /* Match font */
	color: black; /* Match color */
	box-sizing: border-box;
	width: 100%;
	margin: 8px 0;
	text-align: center;
	font-weight: bold;
	transition: transform 0.1s ease-in-out; /* Match transition */
}

.search-input:focus {
	border-color: #007bff;
	outline: none;
	box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.runs-container,
.agents-container,
.attempts-container,
.llm-calls-container {
	overflow-y: scroll;
	width: 100%;
	margin-left: -1vw;
	padding: 1vw;
	padding-bottom: 0;
	height: calc(100% - 60px);

	/* Firefox */
	scrollbar-width: none;

	/* IE and Edge */
	-ms-overflow-style: none;
}

.runs-container::-webkit-scrollbar,
.agents-container::-webkit-scrollbar,
.attempts-container::-webkit-scrollbar,
.llm-calls-container::-webkit-scrollbar {
	display: none; /* Hide scrollbar for Chrome, Safari, Opera */
}

.popup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
}

.popup-content {
	background-color: #fff;
	padding: 20px;
	border-radius: 5px;
	max-width: 80%;
	max-height: 80%;
	overflow-y: auto;
	white-space: pre-wrap;
	word-wrap: break-word;
	position: relative;
	text-align: left;
}

button.close-button {
	background-color: #9bbdf9;
	color: black;
	cursor: pointer;
	transition: background-color 0.2s ease;
	font-size: 16px;
	text-align: center;
	width: 30vw;
	margin: auto;
}

.close-button:hover {
	background-color: #7da2f2;
}

div.popup-content > ul > li > ul > li > ul > li {
	word-break: break-word !important;
}

div.popup-content > ul > li {
	padding: 1em !important;
}

div.popup-content > ul {
	border-radius: 0.25em;
}

.controls-container > button:active,
.controls-container > select:active {
	transform: scale(0.98);
	background-color: #7da2f2;
}

.controls-container {
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
	align-items: center;
	margin-bottom: 5%;
}
.controls-container > button,
.controls-container > div {
	flex-basis: 100%;
	transition: transform 0.1s ease-in-out;
	text-align: center;
	font-weight: bold;
	background-color: #9bbdf9;
	margin-bottom: 2%;
	border-radius: 5px;
	font-size: 1rem;
	color: black;
}

.page-button {
	flex-basis: 49% !important;
}
.controls-container > button:disabled {
	background-color: #ddd;
	color: #999;
	cursor: not-allowed;
	transform: none;
}

/* LoginForm.css */

.login-form {
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 100%;
	max-width: 400px;
	margin: auto;
	padding: 20px;
	background: #f5f5f5;
	border-radius: 10px;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.login-form label {
	display: flex;
	flex-direction: column;
}

.login-form input {
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 5px;
}

.login-form button {
	padding: 10px;
	border: none;
	border-radius: 5px;
	background-color: #9bbdf9;
	color: black;
	cursor: pointer;
	transition: background-color 0.2s ease;
}

.login-form button:hover {
	background-color: #7da2f2;
}

.login-form .error-message {
	color: red;
	text-align: center;
	font-weight: bold;
}

.form-wrapper {
	display: flex;
	height: 100vh;
	position: fixed;
	bottom: 10%;
	left: 35%;
	right: 35%;
}

/* Placeholder styles */
.agents-placeholder,
.llm-calls-placeholder {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-grow: 1;
	color: #999;
	font-size: 1.2rem;
	padding: 20px;
}

.loading-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 200px;
}

.loading-icon {
	font-size: 2rem;
	animation: spin 1s linear infinite;
}

@keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}

.auth-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	background-color: #f9f9f9;
}

.auth-container > * {
	max-width: 300px; /* Reduced from 400px */
	width: 100%;
	padding: 30px 20px; /* Increased vertical padding */
	border: 1px solid #ccc;
	border-radius: 8px;
	background-color: white;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.auth-container img {
	max-width: 60%; /* Slightly increased from 50% */
	height: auto;
	margin-bottom: 30px; /* Increased from 20px */
}

.google-button-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%; 
  }

.agents-list {
	display: flex;
	flex-direction: column;
	min-width: 200px; /* Adjust this value as needed */
}

.agent-placeholder {
	padding: 10px;
	text-align: center;
	border: 1px solid #ccc;
	margin-bottom: 5px;
	background-color: #f0f0f0;
}

/* Add styles for the AdminPage component */
.AdminPage {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	padding: 20px;
	background: #f5f5f5;
	border-radius: 10px;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
	width: 95%;
	max-width: none;
	margin: auto;
}

.admin-columns {
	display: flex;
	gap: 20px;
	width: 100%;
}

.admin-column {
	flex: 1;
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.admin-column .container {
	background: white;
	padding: 20px;
	border-radius: 8px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.AdminPage h1 {
	margin-bottom: 20px;
	font-size: 1.5rem;
	color: #333;
}

.AdminPage form {
	display: flex;
	flex-direction: column;
	gap: 15px;
	width: 100%;
}

.AdminPage label {
	display: flex;
	flex-direction: column;
	font-weight: bold;
	color: #555;
	text-align: left;
}

.AdminPage label.checkbox-label {
	flex-direction: row;
	align-items: center;
	gap: 8px;
	text-align: left;
}

.AdminPage input[type="text"],
.AdminPage input[type="checkbox"] {
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 5px;
	margin-top: 5px;
}

.AdminPage button[type="submit"] {
	padding: 10px;
	border: none;
	border-radius: 5px;
	background-color: #9bbdf9;
	color: black;
	cursor: pointer;
	transition: background-color 0.2s ease;
	font-weight: bold;
}

.AdminPage button[type="submit"]:hover {
	background-color: #7da2f2;
}

.AdminPage .result {
	margin-top: 20px;
	padding: 10px;
	border-radius: 5px;
	background-color: #e0e0e0;
	color: #333;
	text-align: center;
}

.login-page {
	font-family: "Jost", sans-serif;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 20px;
	text-align: center;
	border: none;
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3), 0 6px 6px rgba(0, 0, 0, 0.2);
	background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
	min-height: 40vh;
}

button.close-button,
.controls-container > button,
.login-form button,
.env-switcher-button {
	font-family: "Jost", sans-serif;
}

/* Add these new styles for the dropdown */
.AdminPage select {
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 5px;
	margin-top: 5px;
	width: 100%;
	background-color: white;
	font-family: "Open Sans", sans-serif;
	font-size: 1rem;
	color: #333;
	cursor: pointer;
	transition: border-color 0.2s ease, box-shadow 0.2s ease;
}

.AdminPage select:hover,
.AdminPage select:focus {
	border-color: #9bbdf9;
	outline: none;
	box-shadow: 0 0 5px rgba(155, 189, 249, 0.5);
}

.AdminPage .container {
	width: auto;
	margin-bottom: 0;
}

.AdminPage .container h2 {
	margin-bottom: 15px;
	font-size: 1.3rem;
	color: #333;
}

/* Modify the existing button styles to apply to all buttons in AdminPage */
.AdminPage button {
	padding: 10px;
	border: none;
	border-radius: 5px;
	background-color: #9bbdf9;
	color: black;
	cursor: pointer;
	transition: background-color 0.2s ease, transform 0.1s ease;
	font-weight: bold;
	width: 100%;
	margin-top: 10px;
}

.AdminPage button:hover {
	background-color: #7da2f2;
}

.AdminPage button:active {
	transform: scale(0.98);
}

.AdminPage button:disabled {
	background-color: #ddd;
	color: #999;
	cursor: not-allowed;
}

.CustomerCosts {
	padding: 20px;
	max-width: 1200px;
	margin: 0 auto;
}

.costs-container {
	margin-top: 20px;
	overflow-x: auto;
}

.costs-container tr:hover {
	background-color: #f5f5f5;
}

.costs-container tr {
	transition: background-color 0.2s ease;
}

.CustomerCosts table {
	width: 100%;
	border-collapse: collapse;
	background: white;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.CustomerCosts th,
.CustomerCosts td {
	padding: 12px;
	text-align: left;
	border-bottom: 1px solid #ddd;
}

.CustomerCosts th {
	background-color: #f5f5f5;
	font-weight: bold;
}

.CustomerCosts tr:hover {
	background-color: #f9f9f9;
}

.error {
	color: red;
	padding: 20px;
	text-align: center;
}

/* Add these new styles for the metrics table */
.existing-metrics {
  margin-top: 20px;
  margin-bottom: 30px;
}

.existing-metrics h3 {
  margin-bottom: 15px;
  font-size: 1.2rem;
  color: #333;
}

.existing-metrics table {
  width: 100%;
  border-collapse: collapse;
  background: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.existing-metrics th {
  background-color: #f5f5f5;
  padding: 12px 16px;
  text-align: center;
  font-weight: 600;
  color: #444;
  border-bottom: 2px solid #ddd;
}

.existing-metrics td {
  padding: 12px 16px;
  border-bottom: 1px solid #eee;
}

.existing-metrics tr:last-child td {
  border-bottom: none;
}

.existing-metrics tr:hover {
  background-color: #f9f9f9;
}

.existing-metrics button {
  margin-top: 0 !important;
  width: auto !important;
  padding: 4px !important;
}

.nav-menu {
  background-color: #333;
  padding: 1rem;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.nav-menu a {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
}

.nav-menu a:hover {
  background-color: #444;
}

.hamburger-menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.hamburger-icon {
  font-size: 24px;
  padding: 15px;
  cursor: pointer;
  color: #333;
}

.nav-menu {
  position: fixed;
  top: 0;
  left: -200px;
  width: 200px;
  height: 100vh;
  background-color: transparent;
  padding-top: 60px;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
}

.hamburger-menu:hover .nav-menu {
  left: 0;
  background-color: #7D589F;
}

.nav-menu a {
  color: transparent;
  text-decoration: none;
  padding: 15px 25px;
  display: block;
  transition: all 0.3s ease;
}

.hamburger-menu:hover .nav-menu a {
  color: white;
}

.nav-menu a:hover {
  background-color: #444;
}

.hamburger-menu:hover .nav-menu a.active {
  background-color: #009BDF;
}
